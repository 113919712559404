import React from "react";
import cs from "classnames";
import { Typography } from "@credijusto/minerals";
import style from "./Security.module.scss";

const Item = ({ imgSrc, imgAlt, title, children, className = "" }) => (
  <div className={style.item}>
    <div className={cs(style.imgContent, className)}>
      <img src={imgSrc} alt={imgAlt} />
    </div>
    <div className={style.textContent}>
      <Typography variant="subtitle2" className={style.title}>
        {title}
      </Typography>
      <Typography className={style.text}>{children}</Typography>
    </div>
  </div>
);

export default Item;
