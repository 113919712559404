import React from "react";
import PropTypes from "prop-types";

import covaltoLogo from "./assets/covalto-logo.svg";

function Logo({ className }) {
  return <img alt="covalto logo" src={covaltoLogo} className={className} />;
}
Logo.propTypes = {
  className: PropTypes.string,
};
export default Logo;
