import React, { useState } from "react";
import cs from "classnames";
import { Typography } from "@credijusto/minerals";
import Item from "./Item";
import style from "./Security.module.scss";

const Security = () => {
  const [showInfo, setShowInfo] = useState(false);

  const show = (e) => {
    e.preventDefault();
    setShowInfo(!showInfo);
  };

  return (
    <section className={style.root}>
      <div className={style.sectionDescription}>
        <img src="/assets/img/securityMobile.png" alt="Security" />
        <div className={cs("container", style.description)}>
          <div className={style.text}>
            <Typography
              variant="subtitle1"
              color="text-primary-dark"
              className="bold"
            >
              Protegemos tu contraseña con una encriptación de 256 bits.
            </Typography>
            <Typography
              variant="subtitle2"
              color="text-primary-dark"
              className="mt-2 mb-2"
            >
              Resguardar tu información es muy importante para nosotros, por eso
              la protegemos con los más altos estándares de ciberseguridad.
            </Typography>
            <Typography
              variant="subtitle2"
              color="text-primary-dark"
              className="mt-2 mb-2"
            >
              Aquí puedes conocer las herramientas que usamos para cuidar tus
              datos.
            </Typography>
            <Typography
              as="a"
              variant="link1"
              color="text-primary-dark"
              href="#security"
              onClick={show}
              className={cs(style.toggle, showInfo ? style.isExpanded : "")}
            >
              SABER MAS
            </Typography>
          </div>
        </div>
      </div>
      <div className={cs("container", style.info, showInfo ? style.show : "")}>
        <Item
          imgSrc="/assets/img/timeWhite.svg"
          imgAlt="Supervision"
          title="ISO:27001"
          className={style.neutralBg}
        >
          Es una norma internacional que garantiza la protección de tu
          información. Los centros de datos donde está localizada cuentan con
          esta certificación
        </Item>
        <Item
          imgSrc="/assets/img/timeBlack.svg"
          imgAlt="Encriptación"
          title="Encriptación"
          className={style.primaryBg}
        >
          Se trata de un método de codificación de tus datos para que solo las
          partes autorizadas puedan acceder a ellos.
        </Item>
        <Item
          imgSrc="/assets/img/timeWhite.svg"
          imgAlt="Privacidad"
          title="Estricta política de privacidad"
          className={style.neutralBg}
        >
          Es un documento que explica cómo manejamos tu información, la cual
          usamos para propósitos de evaluación de crédito.
        </Item>
        <Item
          imgSrc="/assets/img/timeBlack.svg"
          imgAlt="Límites"
          title="Límites"
          className={style.primaryBg}
        >
          Lineamientos estrictos para que tu información sea consultada de
          manera interna exclusivamente.
        </Item>
      </div>
    </section>
  );
};

export default Security;
