import React from "react";

function LinkArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path
        fill="#002640"
        d="M12.337 7.56L5.88 14.017l-1.06-1.06L11.275 6.5H5.587V5h8.25v8.25h-1.5V7.56z"
      ></path>
    </svg>
  );
}

export default LinkArrow;
