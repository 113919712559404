import React, { useState, useEffect, Fragment } from "react";
import cs from "classnames";
import {
  Footer as FooterMinerals,
  SocialNetwork,
  Typography,
  Modal,
} from "@credijusto/minerals";
import Security from "./Security";
import logoBuro from "./assets/logo_buro.svg";
import logoIpab from "./assets/LogoIPAB.png";
import styles from "./SiteFooter.module.scss";
import dataFooter from "./data.json";

const LinkModal = ({ data, title }) => {
  const [showModal, setShowModal] = useState(false);
  const [btnPrimaryProps, setBtnPrimaryProps] = useState({});
  const [btnSecondaryProps, setBtnSecondaryProps] = useState({});

  const goBack = () => {
    //navigate(-1);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const btnOkProps = {};
    if (data.btnPrimaryText && data.btnPrimaryBehavior !== "none") {
      if (data.btnPrimaryBehavior === "link" && data.btnPrimaryLink) {
        btnOkProps.as = "a";
        btnOkProps.href = data.btnPrimaryLink;
      } else if (data.btnPrimaryBehavior === "closeModal") {
        btnOkProps.onClick = closeModal;
      }
      setBtnPrimaryProps(btnOkProps);
    }

    const btnCancelProps = {};
    if (data.btnSecondaryText && data.btnSecondaryBehavior !== "none") {
      if (data.btnSecondaryBehavior === "link" && data.btnSecondaryLink) {
        btnCancelProps.as = "a";
        btnCancelProps.href = data.btnSecondaryLink;
      } else if (data.btnSecondaryBehavior === "closeModal") {
        btnCancelProps.onClick = closeModal;
      } else if (data.btnSecondaryBehavior === "returnPreviousPage") {
        btnCancelProps.onClick = goBack;
      }
      setBtnSecondaryProps(btnCancelProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Modal
        title={data.title}
        content={data.content}
        open={showModal}
        type={data.type}
        variant={data.variant}
        hideHeaderCloseButton={data.hideCloseIcon}
        closeOnClickBackdrop={data.closeOnBackdropClick}
        btnOkText={data.btnPrimaryText}
        btnOkProps={btnPrimaryProps}
        btnCancelText={data.btnSecondaryText}
        btnCancelProps={btnSecondaryProps}
      >
        {data.content}
      </Modal>
      <FooterMinerals.Item
        variant="body2"
        className={`${styles.modalLink}`}
        onClick={() => setShowModal(true)}
      >
        {title}
      </FooterMinerals.Item>
    </Fragment>
  );
};

const LinksGroup = ({ data, idx }) => (
  <Fragment>
    {data.title && (
      <FooterMinerals.Item
        isTitle
        variant="h6"
        className={
          idx > 0 &&
          "border-t border-mn-background-tertiary pt-mn-300 mt-mn-300"
        }
      >
        {data.title}
      </FooterMinerals.Item>
    )}
    {data.links
      .sort((a, b) => a.order - b.order)
      .map((l) => {
        if (l.modal) {
          return <LinkModal key={l.id} data={l.modal} title={l.name} />;
        }

        if (l.internal) {
          return (
            <FooterMinerals.Item
              key={l.id}
              variant="body2"
              as={"a"}
              href={`https://covalto.com${l.link}`}
              aria-labelledby={l.name}
            >
              {l.name}
            </FooterMinerals.Item>
          );
        }
        return (
          <FooterMinerals.Item
            key={l.id}
            variant="body2"
            as="a"
            href={!!l.file ? l.file.localFile.url : l.link}
            download={!!l.file}
            aria-labelledby={l.name}
            target="_blank"
          >
            {l.name}
          </FooterMinerals.Item>
        );
      })}
  </Fragment>
);

const Footer = ({
  legalText = null,
  legalTextAdditional = null,
  logosInstitutionEnabled = true,
  contentPanelEnabled = true,
}) => {
  return (
    <>
      <Security />
      <FooterMinerals
        legalText={legalText || dataFooter.footerLegalText}
        institutionOne={{
          alt: "Buró de Entidades Financieras",
          logo: logoBuro,
          website: "https://covalto.com/buro-de-entidades-financieras/",
        }}
        institutionTwo={{
          alt: "Instituto para la Protección al Ahorro Bancario",
          logo: logoIpab,
          website: "https://covalto.com/productos-garantizados-por-el-ipab/",
        }}
        logoEnabled={false}
        legalTextAdditional={legalTextAdditional}
        logosInstitutionEnabled={logosInstitutionEnabled}
        contentPanelEnabled={contentPanelEnabled}
      >
        {dataFooter.footerLinks && dataFooter.footerLinks.length > 0 && (
          <FooterMinerals.Column>
            {dataFooter.footerLinks
              .filter((x) => x.footer_column === 1)
              .map((link, idx) => (
                <LinksGroup key={link.id} data={link} idx={idx} />
              ))}
          </FooterMinerals.Column>
        )}
        <FooterMinerals.Column>
          {dataFooter.footerLinks
            .filter((x) => x.footer_column === 2)
            .map((link, idx) => (
              <LinksGroup key={link.id} data={link} idx={idx} />
            ))}
        </FooterMinerals.Column>
        <FooterMinerals.Column>
          {dataFooter.footerLinks
            .filter((x) => x.footer_column === 3)
            .map((link, idx) => (
              <LinksGroup key={link.id} data={link} idx={idx} />
            ))}
          <FooterMinerals.Item
            variant="body2"
            className="border-t border-mn-background-tertiary pt-mn-300 mt-mn-300 flex"
          >
            <span className="mr-mn-200">{dataFooter.phoneSwitchTitle}</span>

            <Typography
              variant="body2"
              color="primary-dark"
              as="a"
              href={`tel:${dataFooter.phoneSwitch}`}
              className={`${styles.phone}`}
            >
              {dataFooter.phoneSwitch}
            </Typography>
          </FooterMinerals.Item>
          <FooterMinerals.Item
            variant="body2"
            className={`flex ${styles.phoneItem}`}
          >
            <span className="mr-mn-200">{dataFooter.phoneTitle}</span>
            <Typography
              variant="body2"
              color="primary-dark"
              as="a"
              href={`tel:${dataFooter.phone}`}
              className={`${styles.phone}`}
            >
              {`${dataFooter.phone}`}
            </Typography>
            {dataFooter.phoneCovalto && (
              <Typography
                variant="body2"
                color="primary-dark"
                as="a"
                href={`tel:${dataFooter.phoneCovalto}`}
                className={`${styles.phone} pl-mn-200`}
              >
                {`/ ${dataFooter.phoneCovalto}`}
              </Typography>
            )}
          </FooterMinerals.Item>
          <FooterMinerals.Item
            variant="body2"
            as="a"
            href="mailto:servicio@covalto.com"
            target="_blank"
          >
            servicio@covalto.com
          </FooterMinerals.Item>
          <FooterMinerals.Item
            variant="body2"
            className="mb-mn-200"
            as="a"
            href={dataFooter.address.urlGoogleMaps}
            target="_blank"
          >
            {`${dataFooter.address.street} ${dataFooter.address.outdoorNumber}, ${dataFooter.address.interiorNumber}, ${dataFooter.address.suburb}, C.P. ${dataFooter.address.postalCode}, ${dataFooter.address.city}, ${dataFooter.address.state}.`}
          </FooterMinerals.Item>
          <SocialNetwork
            dark
            facebook="https://www.facebook.com/bancocovalto"
            linkedin="https://www.linkedin.com/company/bancocovalto"
            instagram="https://www.instagram.com/bancocovalto"
            twitter="https://twitter.com/bancocovalto"
          />
        </FooterMinerals.Column>
      </FooterMinerals>
    </>
  );
};

export default Footer;
