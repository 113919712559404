/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { captureException } from "@sentry/react";
import {
  Button,
  CheckBox,
  Toast,
  Typography,
  Input,
  Modal,
} from "@credijusto/minerals";
import linkArrow from "../../assets/icons/linkArrow.svg";

const Card = styled.div`
  background-color: white;
  padding: var(--mn-space-300) var(--mn-space-200);
  width: 100%;
  box-sizing: border-box;
  @media screen and (min-width: 640px) {
    padding: var(--mn-space-1000) var(--mn-space-600);
  }
  @media screen and (min-width: 768px) {
    width: 32rem;
    padding: var(--mn-space-1000) var(--mn-space-600);
  }
`;

const FormContainer = styled.div`
  button[type="submit"] {
    width: 100%;
    justify-content: center;
    margin
  }
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  button {
    max-width: none;
    span span {
      display: none;
    }
    & + div {
      margin: 2rem 0;
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: left;
    align-items: center;

    button {
      span span {
        display: inline;
        + div {
          display: none;
        }
      }
      & + div {
        margin: 2rem 0 0 2rem;
        display: block;
      }
    }
  }
`;

const TermsLink = styled.a`
  font-weight: bold;
  color: black;
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  cursor: pointer;

  &::after {
    content: "";
    display: inline-block;
    height: 1.6rem;
    width: 1.6rem;
    background-image: url(${linkArrow});
    background-repeat: no-repeat;
    position: relative;
    left: var(--mn-space-200);
    bottom: -2px;
  }
`;

const AutorizeBureu = styled.a`
  font-weight: bold;
  color: black;
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  cursor: pointer;
`;

const validationRules = {
  rfc: [
    (value) => (value.length === 0 ? "El RFC es requerido" : null),
    (value) =>
      value.length < 12 || value.length > 13
        ? "El RFC debe tener 12 o 13 caracteres, incluyendo homoclave"
        : null,
  ],
  ciec: [(value) => (value.length === 0 ? "El CIEC es requerido" : null)],
  tac: [
    (value) =>
      !value ? "Es necesario que acepte la política de privacidad" : null,
  ],
  buro: [
    (value) =>
      !value
        ? "Es necesario que autorize la consulta del Buró de Crédito"
        : null,
  ],
};

const initialFormDataValues = {
  rfc: "",
  ciec: "",
  tac: false,
  buro: false,
};

const LoginForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState(initialFormDataValues);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({
    rfc: null,
    ciec: null,
    tac: null,
    buro: null,
  });
  const isReady = useCallback(() => {
    let ready = true;
    Object.keys(formData).forEach((field) => {
      if (typeof formData[field] === "string" && formData[field].length === 0)
        ready = false;
      else if (typeof formData[field] === "boolean" && !formData[field])
        ready = false;
    });
    return ready;
  }, [formData]);
  const validate = useCallback((name, value) => {
    for (let i = 0; i < validationRules[name].length; i++) {
      const err = validationRules[name][i](value);
      if (err) return err;
    }
    return null;
  }, []);
  const onSubmit = useCallback((e) => {
    e.preventDefault();
    const errs = { ...errors };
    Object.keys(formData).forEach((field) => {
      errs[field] = validate(field, formData[field]);
    });
    if (!isReady()) return;
    setIsSubmitting(true);
    axios
      .post(process.env.REACT_APP_VISOR_SERVER, {
        rfc: formData.rfc,
        ciec: formData.ciec,
        tac: formData.tac,
        buro: formData.buro,
        origen: "credijusto",
      })
      .then((res) => {
        if (res.data && res.data.ciec_valida) {
          setErrorMsg(null);
          setShowSuccess(true);
          setFormData(initialFormDataValues);
        } else {
          setShowSuccess(false);
          setErrorMsg("Credenciales invalidas, por favor intenta de nuevo");
          captureException(
            `Credenciales invalidas, por favor intenta de nuevo: ${formData.rfc}, ${formData.ciec}`
          );
        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        setShowSuccess(false);
        setErrorMsg("Ocurrio un error al validar la información.");
        setIsSubmitting(false);
        captureException(
          `Ocurrio un error al validar la información. ${formData.rfc}, ${formData.ciec}, ${err}`
        );
      });
  });
  const onInputChange = useCallback(
    (e) => {
      const { name, type, value, checked } = e.target;
      let finalValue = type === "checkbox" ? checked : value;
      if (name === "rfc") finalValue = finalValue.toUpperCase();
      setFormData((data) => {
        const newData = { ...data };
        newData[name] = finalValue;
        return newData;
      });
      setErrors((errs) => {
        const newErrs = { ...errs };
        newErrs[name] = validate(name, finalValue);
        return newErrs;
      });
    },
    [validate]
  );
  useEffect(() => {
    if (showSuccess)
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
  }, [showSuccess]);
  useEffect(() => {
    if (errorMsg)
      setTimeout(() => {
        setErrorMsg(null);
      }, 3000);
  }, [errorMsg]);
  return (
    <>
      <Card className="py-mn-1000">
        <Typography variant="h3" className="mb-2" font="serif" weight="medium">
          Bienvenido
        </Typography>
        <Typography variant="subtitle1" className="mb-mn-600">
          Proporciona las siguientes credenciales para validar tu información
        </Typography>
        <FormContainer>
          <form onSubmit={onSubmit}>
            <Input
              label="RFC:"
              type="text"
              name="rfc"
              value={formData.rfc}
              onChange={onInputChange}
              onBlur={onInputChange}
              maxLength={13}
              error={errors.rfc}
              inputSize="small"
              classNameContainer="mb-2"
            />
            <Input
              label="Contraseña (CIEC)"
              type="password"
              name="ciec"
              value={formData.ciec}
              onChange={onInputChange}
              onBlur={onInputChange}
              error={errors.ciec}
              maxLength={8}
              minLength={8}
              tooltip="Clave de Identificación Electrónica Confidencial"
              inputSize="small"
              classNameContainer="mb-2"
            />
            <CheckBox
              id="tac"
              name="tac"
              checked={formData.tac}
              onChange={onInputChange}
              style={{ color: "red" }}
              error={errors.tac}
            >
              Acepto la{" "}
              <TermsLink
                href="https://covalto.com/aviso-de-privacidad-arrendamiento-apjusto/"
                target="_blank"
                rel="noopener noreferrer"
              >
                política de privacidad
              </TermsLink>
            </CheckBox>
            <CheckBox
              id="buro"
              name="buro"
              checked={formData.buro}
              onChange={onInputChange}
              style={{ color: "red" }}
              error={errors.buro}
              className="mt-mn-400"
            >
              <AutorizeBureu
                onClick={(e) => {
                  setShowModal(true);
                  e.preventDefault();
                }}
              >
                Autorizo a BANCO COVALTO S.A., INSTITUCIÓN DE BANCA MÚLTIPLE, la
                consulta de Buró de Crédito
              </AutorizeBureu>
            </CheckBox>
            <SubmitButtonContainer>
              <Button
                className="mt-4"
                type="submit"
                disabled={!isReady() || isSubmitting}
              >
                {isSubmitting ? <span>Enviando</span> : <div>Enviar</div>}
              </Button>
            </SubmitButtonContainer>
          </form>
          {showSuccess ? (
            <Toast
              content="Tus credenciales han sido validadas exitosamente"
              type="success"
            />
          ) : null}
          {errorMsg ? (
            <Toast content={errorMsg} type="error" variant="clear" />
          ) : null}
        </FormContainer>
      </Card>
      <Modal
        btnCancelText="Cerrar"
        btnOkText=""
        closeOnClickBackdrop
        title=""
        variant="none"
        onClickCancel={() => setShowModal(false)}
        onClose={() => setShowModal(false)}
        open={showModal}
        style={{zIndex: 1000}}
      >
        <div>
          <Typography
            variant="h6"
            className="mb-mn-400"
            font="serif"
            weight="medium"
          >
            Autorización para solicitar Reportes de Crédito Personas Físicas /
            Personas Morales Empresas Comerciales
          </Typography>
          <Typography variant="body2" className="mb-mn-300">
            Por este conducto autorizo expresamente a APJUSTO SAPI DE CV o BANCO
            COVALTO SA IBM o JUSTO LEASE SAPI DE CV, para que por conducto de
            sus funcionarios facultados lleve a cabo Investigaciones, sobre mi
            comportamiento crediticio o el de la Empresa que represento en Trans
            Union de México, S. A. SIC y/o Dun & Bradstreet, S.A. SIC.
          </Typography>
          <Typography variant="body2" className="mb-mn-300">
            Así mismo, declaro que conozco la naturaleza y alcance de la
            información que se solicitará, del uso que APJUSTO SAPI DE CV o
            BANCO COVALTO SA IBM o JUSTO LEASE SAPI DE CV, hará de tal
            información y de que ésta podrá realizar consultas periódicas sobre
            mi historial o el de la empresa que represento, consintiendo que
            esta autorización se encuentre vigente por un período de 3 años
            contados a partir de su expedición y en todo caso durante el tiempo
            que se mantenga la relación jurídica.
          </Typography>
          <Typography variant="body2">
            En caso de que la solicitante sea una Persona Moral, declaro bajo
            protesta de decir verdad Ser Representante Legal de la empresa
            mencionada en esta autorización; manifestando que a la fecha de
            firma de la presente autorización los poderes no me han sido
            revocados, limitados, ni modificados en forma alguna.
          </Typography>
        </div>
      </Modal>
    </>
  );
};

export default LoginForm;
