import React, { useMemo } from "react";
import { Button, NavV3 } from "@credijusto/minerals";
import styles from "./SiteHeaderV3.module.css";
import LogoCovalto from "../Logo/LogoCovalto";
import LogoCovaltoSlogan from "../Logo/LogoCovaltoSlogan";
import getDynamicLink from "../../utils/getDynamicLink";
import menu from "./data.json";

const BtnAction = ({ name, slug }) => {
  const handleClick = () => {
    const btn = document.getElementById("btnNavCTA");
    btn.blur();
    btn.classList.add(styles.btn);
  };

  const hadleMouseEnter = () => {
    const btn = document.getElementById("btnNavCTA");
    btn.classList.remove(styles.btn);
  };
  return (
    <Button
      id="btnNavCTA"
      as="a"
      href={slug}
      target="_blank"
      variant="secondary"
      className={`${styles.VirtualButton} whitespace-nowrap text-mn-body2 leading-mn-body2 tracking-mn-body2 text-mn-text-primary`}
      onClick={handleClick}
      onMouseEnter={hadleMouseEnter}
    >
      {name}
    </Button>
  );
};

const Logo = () => {
  return (
    <a href="/">
      <LogoCovalto className="hidden md:block" />
      <LogoCovaltoSlogan className="md:hidden" />
    </a>
  );
};

// const LinkWithEvent = ({ to, event, children, ...props }) => {
//   return (
//     <a href={to} onClick={handleClick} {...props}>
//       {children}
//     </a>
//   );
// };

const NavComponent = ({
  hideLogoTagline = false,
  language = false,
  languageSelected = "es",
  onChangeLanguage = () => {},
  handleMegamenuOverview = () => {},
  handleMegamenuProducts = () => {},
  showMegamenuProducts = false,
  showMegamenuOverview = false,
}) => {
  // const { strapiHeaderMenuV3: menu } = useGetHeaderData();
  const eventMegaMenuProducts = (elements) => {
    handleMegamenuProducts(elements, menu.subtitle);
  };

  const menuDesktop = menu.menuElements.map((item) => {
    return {
      name: item.title,
      type:
        item.strapi_component === "nav-header.menu-element-link"
          ? "link"
          : item.strapi_component === "nav-header.mega-menu-products"
          ? "megamenu-productos"
          : "megamenu-overview",
      icon: item.icon ? item.icon.url : null,
      slug: item.slug ? getDynamicLink(item.slug) : null,
      data: item,
    };
  });

  const productsMenu = [];

  menu.menuElements
    .filter(
      (item) =>
        item.strapi_component == "nav-header.mega-menu-products" ||
        item.strapi_component == "nav-header.menu-element-sobre-covalto"
    )
    .map((item) => {
      if (item.strapi_component == "nav-header.mega-menu-products") {
        item.groups.forEach((group) => {
          group.products.forEach((product) => {
            if (product.links.length > 0) {
              const prodInsert = {
                title: product.title,
                products: product.links.map((link) => ({
                  name: link.name,
                  slug: link.slug,
                  new: link.new,
                })),
                orderMobile: product.orderMobile,
              };
              const existingIndex = productsMenu.findIndex(
                (prod) => prod.title === product.title
              );

              if (existingIndex !== -1) {
                productsMenu[existingIndex].products.push(
                  ...prodInsert.products
                );
              } else {
                productsMenu.push(prodInsert);
              }
            }
          });
        });
      } else if (
        item.strapi_component === "nav-header.menu-element-sobre-covalto"
      ) {
        item.columnItems.forEach((column) => {
          productsMenu.push({
            title: column.columnName,
            products: column.links.map((link) => ({
              name: link.name,
              slug: link.slug,
            })),
            orderMobile: column.orderMobile,
          });
        });
      }
    });

  const menuMobile = {
    subtitle: menu.subtitle,
    cards: menu.menuElements
      .filter(
        (item) => item.strapi_component === "nav-header.menu-element-link"
      )
      .map((item) => {
        return {
          name: item.title,
          slug: getDynamicLink(item.slug),
          type: "link",
          icon: item.icon ? item.icon.url : null,
        };
      }),
    menu: productsMenu.sort((a, b) => a.orderMobile - b.orderMobile),
  };

  const blogElement = {
    title: menu.menuElements[3].blogTitle,
    products: [
      {
        slug: "/blog/",
        name: "Visitar el blog",
        icon: "https://covalto-production-website.s3.amazonaws.com/Group_168b08b696.svg",
      },
    ],
  };
  menuMobile.menu.push(blogElement);

  return (
    <>
      <NavV3
        logo={<Logo />}
        sticky={true}
        btnAction={
          <BtnAction slug={menu.ctaButtonSlug} name={menu.ctaButtonTitle} />
        }
        asLinkElements="a"
        menuDesktop={menuDesktop}
        menuMobile={menuMobile}
        handleMegamenuOverview={handleMegamenuOverview}
        handleMegamenuProducts={eventMegaMenuProducts}
        isMegamenuProductsOpen={showMegamenuProducts}
        isMegamenuOverviewOpen={showMegamenuOverview}
        className={styles.nav}
      />
    </>
  );
};

export default NavComponent;
