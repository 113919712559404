import React from "react";
import { Button, Typography } from "@credijusto/minerals";
import styles from "./MegamenuAboutUs.module.scss";
import LinkArrow from "./icons/LinkArrow";
import articles from "./data.json";

const MegamenuAboutUs = ({ data, slideDown }) => {
  return (
    <div
      className={`${styles.container} bg-mn-background-tertiary py-4 ${
        slideDown ? styles.slideDown : styles.slideUp
      } `}
    >
      <div className="container containerSecond mx-auto hidden xl:flex gap-mn-900 items-start py-0 md:py-4 px-4 md:px-6 lg:px-14">
        {data.columnItems.map((ci) => (
          <div key={ci.id}>
            <Typography
              color="text-primary"
              variant="subtitle2"
              font="sans"
              weight="medium"
              className="mb-mn-200"
            >
              {ci.columnName}
            </Typography>
            <ul className="ml-mn-300">
              {ci.links.map((item, itemIndex) => (
                <li key={item.id} className="mb-1">
                  <Typography
                    color="text-primary"
                    variant="body2"
                    font="sans"
                    className={styles.links}
                    as="a"
                    href={item.slug}
                  >
                    {item.name}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div className="flex-1 flex flex-col">
          <Typography
            color="text-primary"
            variant="subtitle2"
            font="sans"
            weight="medium"
            className="mb-mn-200"
          >
            {data.blogTitle}
          </Typography>
          <ul className="mb-mn-300 ml-mn-300">
            <li className="mb-1">
              <Typography
                color="text-primary"
                variant="body2"
                font="sans"
                className={`flex gap-mn-200 items-center ${styles.links}`}
                as="a"
                href="https://covalto.com/blog/"
              >
                Visitar el blog <LinkArrow />
              </Typography>
            </li>
          </ul>
          <div
            className={`flex-1 grid lg:grid-cols-2 xl:grid-cols-3 gap-4 ${styles.articleContainer}`}
          >
            {articles.map((item) => (
              <a
                key={item.strapiId}
                href={`https://covalto.com/blog/${item.slug}/`}
                className={styles.articleCard}
              >
                <div className="flex gap-4 h-full">
                  <div
                    className={styles.articleImage}
                    style={{
                      backgroundImage: `url(${item.images.mobileFeaturedListImage.url})`,
                    }}
                  />
                  {/* <img src={item.images.mainImage.url} alt="article" /> */}
                  <div className="flex flex-1 flex-col gap-mn-200">
                    <Typography
                      color="text-primary"
                      variant="body2"
                      font="sans"
                      weight="medium"
                      className={styles.twoLines}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      color="text-primary"
                      variant="body2"
                      font="sans"
                      className={styles.twoLines}
                    >
                      {item.description}
                    </Typography>
                    <div className="flex flex-1 justify-end items-end">
                      <Button
                        as="button"
                        type="button"
                        iconRight={<LinkArrow />}
                        variant="textButton"
                        className={styles.noPadding}
                      >
                        Leer más
                      </Button>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MegamenuAboutUs;
