import React from "react";
import cs from "classnames";
import LoginForm from "../LoginForm";
import style from "./Main.module.scss";

const Main = () => (
  <div className={style.hero}>
    <img src="/assets/img/heroBgMovile.svg" alt="Credijusto" />
    <div className={cs("container", style.main)}>
      <LoginForm />
    </div>
  </div>
);

export default Main;
