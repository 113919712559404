import React, { useEffect, useRef, useState } from "react";
import "@credijusto/minerals/lib/esm/index.css";
import { ThemeProvider } from "@credijusto/minerals";
import "../../assets/styles/styles.scss";
import Header from "../SiteHeaderV3/HeaderV3";
import { MegamenuAboutUs, MegamenuProducts } from "../Megamenus";
import Main from "../Main";
import Footer from "../Footer";
import styles from "./App.module.scss";

function App() {
  const [showMegamenuAboutUs, setShowMegamenuAboutUs] = useState(false);
  const [showMegamenuProducts, setShowMegamenuProducts] = useState(false);
  const [slideMegamenuOverview, setSlideMegamenuOverview] = useState(false);
  const [slideMegamenuProducts, setSlideMegamenuProducts] = useState(false);
  const [subtitle, setSubtitle] = useState("");
  const megamenuAboutUsRef = useRef(null);
  const megamenuProductsRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      setTimeout(() => {
        if (
          showMegamenuAboutUs &&
          megamenuAboutUsRef.current &&
          !megamenuAboutUsRef.current.contains(event.target)
        ) {
          setSlideMegamenuOverview(false);
          setTimeout(() => {
            setShowMegamenuAboutUs(false);
          }, 150);
        }
        if (
          showMegamenuProducts &&
          megamenuProductsRef.current &&
          !megamenuProductsRef.current.contains(event.target)
        ) {
          setSlideMegamenuProducts(false);
          setTimeout(() => {
            setShowMegamenuProducts(false);
          }, 150);
        }
      }, 150);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMegamenuAboutUs, showMegamenuProducts]);

  const handleShowMegamenuAboutUs = (element) => {
    setSlideMegamenuOverview(true);
    if (!showMegamenuAboutUs) {
      setShowMegamenuAboutUs(element.data);
    }
  };

  const handleShowMegamenuProducts = (element, subtitle) => {
    setSlideMegamenuProducts(true);
    if (!showMegamenuProducts) {
      setShowMegamenuProducts(element.data);
    }
    setSubtitle(subtitle);
  };

  return (
    <div className="App">
      <ThemeProvider theme="Covalto">
        <Header
          handleMegamenuOverview={handleShowMegamenuAboutUs}
          handleMegamenuProducts={handleShowMegamenuProducts}
          showMegamenuProducts={showMegamenuProducts}
          showMegamenuOverview={showMegamenuAboutUs}
        />
        <div
          ref={megamenuAboutUsRef}
          className={`${styles.top} sticky right-0 left-0 z-50`}
        >
          {showMegamenuAboutUs && (
            <MegamenuAboutUs
              data={showMegamenuAboutUs}
              slideDown={slideMegamenuOverview}
            />
          )}
        </div>

        <div
          ref={megamenuProductsRef}
          className={`${styles.top} sticky right-0 left-0 z-50`}
        >
          {showMegamenuProducts && (
            <MegamenuProducts
              data={showMegamenuProducts}
              subtitle={subtitle}
              slideDown={slideMegamenuProducts}
            />
          )}
        </div>
        <Main />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
