import React, { useEffect, useState } from "react";
import { Typography, Line, Button } from "@credijusto/minerals";
import styles from "./MegamenuProducts.module.scss";

const MegamenuProducts = ({ data, subtitle, slideDown }) => {
  const [formatedData, setFormatedData] = useState({ groups: [] });
  const [firstLoad, setFirstLoad] = useState(true);
  const [selected, setSelected] = useState(data.groups[0].id);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);

      const fData = {
        groupsTitle: data.title,
        groups: data.groups.map((group) => ({
          id: group.id,
          icon: group.icon.url,
          title: group.title,
          columns: Object.values(
            group.products.reduce((columns, product) => {
              // Agrupa los productos por columna
              const columnIndex = product.column - 1;
              if (!columns[columnIndex]) {
                columns[columnIndex] = { products: [] };
              }
              columns[columnIndex].products.push({
                id: product.id,
                title: product.title,
                links: product.links.map((link) => ({
                  id: link.id,
                  name: link.name,
                  slug: link.slug,
                  ...(link.new && { new: link.new }), // Añade "new: true" si existe
                })),
              });
              return columns;
            }, {})
          ),
        })),
      };
      setFormatedData(fData);
      setColumns(fData.groups.find((x) => x.id === selected).columns);
    } else {
      setColumns(formatedData.groups.find((x) => x.id === selected).columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div
      className={`${styles.container} bg-mn-background-tertiary py-4  ${
        slideDown ? styles.slideDown : styles.slideUp
      } `}
    >
      <div
        className={`container containerSecond mx-auto hidden xl:flex flex-col md:flex-row items-start py-0 md:py-4 px-4 md:px-6 md:px-14`}
      >
        <div className="hidden md:flex flex-col items-center justify-start gap-4">
          <Typography
            color="text-primary"
            variant="subtitle1"
            font="sans"
            weight="medium"
          >
            {subtitle}
          </Typography>
          {formatedData.groups.map((x, i) => (
            <Button
              key={x.id}
              as="button"
              type="button"
              iconLeft={<img src={x.icon} alt="icon" width="24" />}
              iconRight=""
              variant={selected === x.id ? "primary" : "textButton"}
              onClick={() => setSelected(x.id)}
              className={styles.button}
            >
              {x.title}
            </Button>
          ))}
        </div>
        <Line
          width="1px"
          height="auto"
          colorVariant={`mn-background-primary-dark mx-4 hidden md:block ${styles.line}`}
        />
        <div className="flex-1 hidden md:grid md:grid-cols-2 xl:grid-cols-4 gap-4">
          {columns.map((column, columnIndex) => (
            <div key={columnIndex}>
              {column.products.map((product, productIndex) => (
                <div key={productIndex} className="mb-4">
                  <Typography
                    color="text-primary"
                    variant="subtitle2"
                    font="sans"
                    weight="medium"
                    className="mb-2"
                  >
                    {product.title}
                  </Typography>
                  <ul className={styles.li}>
                    {product.links.map((item, itemIndex) => (
                      <li key={itemIndex} className="mb-1">
                        <Typography
                          color="text-primary"
                          variant="body2"
                          font="sans"
                          className={styles.links}
                          as="a"
                          href={item.slug}
                          // onClick={() => {
                          //   const itemPathname = new URL(item.slug, window.location.origin).pathname;
                          //   if (location.pathname === itemPathname) {
                          //     if (typeof window !== "undefined") {
                          //       window.location.reload();
                          //     }
                          //   }
                          // }}
                        >
                          {item.name}
                          {item.new && (
                            <span
                              className={`text-white text-xs rounded ${styles.tag}`}
                            >
                              NUEVO
                            </span>
                          )}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MegamenuProducts;
