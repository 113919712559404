const regex = /{{([^}}]*)}}/g;

const getDynamicLink = (link) => {
  if (!link) return "";

  let dynamicLink = link;

  const matches = link.match(regex);

  if (!matches) return link;

  for (const m of matches) {
    const variable = m.slice(2, -2).replaceAll(" ", "");
    const values = variable.split("||");

    let value =
      typeof window !== `undefined` ? localStorage.getItem(values[0]) : null;

    if (!value) {
      value = values[1] || "";
    }

    dynamicLink = dynamicLink.replace(m, value);
  }

  return dynamicLink;
};

export default getDynamicLink;
